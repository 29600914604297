@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/fonts/KrungthaiFast/KrungthaiFast-Bold.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-Bold.woff') format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/fonts/KrungthaiFast/KrungthaiFast-BoldItalic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-BoldItalic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-BoldItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/fonts/KrungthaiFast/KrungthaiFast-Italic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-Italic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-Italic.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/fonts/KrungthaiFast/KrungthaiFast-Light.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-Light.woff') format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/fonts/KrungthaiFast/KrungthaiFast-LightItalic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-LightItalic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-LightItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/fonts/KrungthaiFast/KrungthaiFast-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-Regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/fonts/KrungthaiFast/KrungthaiFast-UltraLight.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-UltraLight.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-UltraLight.ttf')
      format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Krungthai Fast';
  src: url('/assets/fonts/KrungthaiFast/KrungthaiFast-UltraLightItalic.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-UltraLightItalic.woff')
      format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/KrungthaiFast/KrungthaiFast-UltraLightItalic.ttf')
      format('truetype');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
