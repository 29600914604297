@import 'node_modules/primeicons/primeicons';
@import 'node_modules/primeng/resources/themes/saga-blue/theme';
@import 'node_modules/primeng/resources/primeng.min';
@import 'node_modules/primeflex/primeflex';
@import '../../../assets/fonts/font';
@import '../../../assets/style/main';
@import 'variables';

:root {
  --primary-kt-blue: #00a6e6;
  --next-disabled-text: #8a9fab;
  --secondary-color: #5a6e7b;
  --fs-header: 20px;
  --fs-normal: 16px;
  --fs-small: 14px;
}

body {
  margin: 0px;
  background-color: #f9fafb;
  min-width: fit-content;
  font-family: $font-family;
  font-size: 16px;
}

.p-component {
  font-family: $font-family;
  font-size: 16px;
  margin: 0px;
}

.p-card {
  border-radius: 16px !important;
  color: $black-color;
}

.p-checkbox {
  .p-checkbox-box {
    border: 1px solid #ced4da;
  }
  .p-checkbox-box.p-highlight {
    background: $primary-color !important;
    border-color: $primary-color !important;
  }
}
.p-checkbox-label {
  margin-left: 16px;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #8a9fab !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #8a9fab !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #8a9fab !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #8a9fab !important;
}

.p-inputnumber-input {
  text-align: right;
  border-radius: 8px;
  border: 1px solid #bcccd6;
}

.p-dropdown {
  border: 1px solid #bcccd6;
  border-radius: 8px !important;

  .p-dropdown-trigger {
    color: $primary-color !important;
    margin-right: 8px;
  }

  .p-dropdown-label.p-placeholder {
    color: #8a9fab !important;
  }
}

.p-radiobutton {
  .p-radiobutton-box {
    border: 1px solid #bcccd6 !important;
  }

  .p-radiobutton-box.p-highlight:hover {
    border-color: $primary-color !important;
    background: $white-color !important;
  }

  .p-radiobutton-box.p-highlight {
    border-color: $primary-color !important;
    background: $white-color !important;
    &.p-focus {
      box-shadow: none;
    }
  }

  .p-radiobutton-box .p-radiobutton-icon {
    background-color: $primary-color !important;
  }
}

::ng-deep .p-card {
  border-radius: 16pt;
}

.head {
  font-size: 16pt;
  font-weight: bold;
}

// Modal
.p-dialog {
  border-radius: 16pt;

  .p-dialog-title {
    margin-top: 16px;
  }

  .p-dialog-content {
    white-space: pre-line;
    border-radius: 16px;
    font-size: 14px;
    padding: 24px;
  }
}
// Modal

.p-inputtext {
  border: 1px solid #bcccd6;
  font-family: $font-family;
  padding-left: 16px !important;
  color: #000000;

  &[type='number'] {
    padding-left: 10px !important;
  }
}

.p-slider .p-slider-handle {
  box-shadow: 0 2px 4px 0 rgba(188, 204, 214, 0.8);
  border: none;
}

// Common use
.w-100 {
  width: 100%;
}
.fw-bold {
  font-weight: bold;
}
.fw-normal {
  font-weight: normal;
}

.bg-white {
  background-color: $white-color;
}

.event-unselect {
  pointer-events: none;
  user-select: none;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: inherit;
}

.pi {
  font-size: 24px;
}

.p-fluid .p-dropdown .p-dropdown-label {
  color: #000000;
}

.p-inputtext:enabled:hover {
  border-color: #00a6e6;
  box-shadow: none;
  background-color: #ffffff;
}

.p-dropdown:not(.p-disabled).p-focus {
  border-color: #00a6e6;
  box-shadow: none;
  background-color: #ffffff;
}

.ng-submitted .p-inputtext.ng-invalid,
.p-inputtext.ng-touched.ng-invalid {
  border-color: $red-color;
}

.p-disabled,
.p-component:disabled {
  opacity: 1;
}
