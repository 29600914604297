/* Font Variable */
$font-family: 'Krungthai Fast', Roboto, Helvetica Neue Light, Helvetica Neue,
  Helvetica, Arial, Lucida Grande, sans-serif;

/* Color Variable */
// $primary-darkest-color: #0098c6;
// $primary-dark-color: #059fce;
$primary-color: #00a6e6;
// $primary-light-color: #3ecbff;
// $primary-lighted-color: #a2e6ff;
// $primary-blue-green: #53c3d2;

$black-color: #000000;
// $darkest-color: #30323a;
// $dark-grey-color: #565b6e;
// $dark-light-grey-color: #5a6e7b;
// $grey-color: #8a9fab;
// $light-hard-grey-color: #bcccd6;
// $light-grey-color: #dfe2f0;
// $light-color: #fafafa;
$white-color: #ffffff;

// $dark-green-color: #4f9800;
// $green-color: #63be00;
// $light-green-color: #82cb33;
// $green-yellow: #00b663;

// $dark-red-color: #af0000;
$red-color: #db0000;
// $light-red-color: #e23333;

// $dark-orange-color: #f08700;
// $orange-color: #f49b00;
// $light-orange-color: #f6af33;

// $active-color: #eaf0f5;
// $disable-color: #f2f3f6;
// $active-red-color: #ff4f4f;

// $bg-color: #eaf0f5;
// $bg-table-color: #f2f3f6;

// $dark-blue: #0962c5;

$border-input-color: #bcccd6;
